/* eslint no-dupe-class-members: 0 */

import ReportRequest from "../report/ReportRequest";
import DocxBuilder from "../docxgen/DocxBuilder";
import ReportBuilderBase from "./ReportBuilderBase";
import * as disp from "../utility/display";

export default class ReportBuilderText extends ReportBuilderBase {

    ReportName = "textResume";
    #request;
    #document;

    constructor(customizations) {
        super(customizations);
    }

    CreateText(nameDisplay, addressDisplay, adjectiveDisplay, hardSkills, hardSkillsLabels, softSkills, roles, careerInterestDisplay, educationList, workList, workDict, languages, licenses, profOrg, volunteerOrg, interest, otherSkills, points, profSummary) {
        super.CheckParam(nameDisplay, "nameDisplay", "BuildReport");
        super.CheckParam(addressDisplay, "addressDisplay", "BuildReport");
        super.CheckParam(adjectiveDisplay, "adjectiveDisplay", "BuildReport");
        super.CheckParam(hardSkills, "hardSkills", "BuildReport");
        super.CheckParam(hardSkillsLabels, "hardSkillsLabels", "BuildReport");
        super.CheckParam(softSkills, "softSkills", "BuildReport");
        super.CheckParam(roles, "roles", "BuildReport");
        super.CheckParam(careerInterestDisplay, "careerInterestDisplay", "BuildReport");
        super.CheckParam(educationList, "educationList", "BuildReport");
        super.CheckParam(workList, "workList", "BuildReport");
        super.CheckParam(workDict, "workDict", "BuildReport");
        super.CheckParam(languages, "languages", "BuildReport");
        super.CheckParam(licenses, "licenses", "BuildReport");
        super.CheckParam(profOrg, "profOrg", "BuildReport");
        super.CheckParam(volunteerOrg, "volunteerOrg", "BuildReport");
        super.CheckParam(interest, "interest", "BuildReport");
        super.CheckParam(otherSkills, "otherSkills", "BuildReport");
        super.CheckParam(points, "points", "BuildReport");
        super.CheckParam(profSummary, "profSummary", "BuildReport")

        // set namedisplay, address display
        this.nameDisplay = nameDisplay;
        this.addressDisplay = addressDisplay;

        // Build professional summary
        if (!disp.IsNullorWhitespace(profSummary)) {
            this.profSum = profSummary;
        }
        else {
            let roleDisplay = super.BuildStringList(roles.map(r => r.text));
            let hardSkillsTopDisplay = super.BuildStringList(hardSkills.filter(h => h.level == undefined || h.level == 1).map(h => h.text));
            let softSkillsDisplay = super.BuildStringList(softSkills.map(s => s.text));
            this.profSum = super.BuildProfessionalSummary(adjectiveDisplay, careerInterestDisplay, roleDisplay, hardSkillsTopDisplay, softSkillsDisplay, workDict);
        }

        // Build skills
        [this.skillHeaders, this.skillValues] = super.BuildSkillArray(hardSkills, hardSkillsLabels, otherSkills);

        // Build additional items array
        this.addArr = this.BuildAdditionalItems(languages, licenses, profOrg, volunteerOrg, interest, points);

    }

    BuildDocx(nameDisplay, addressDisplay, adjectiveDisplay, hardSkills, hardSkillsLabels, softSkills, roles, careerInterestDisplay, educationList, workList, workDict, languages, licenses, profOrg, volunteerOrg, interest, otherSkills, points, profSummary) {

        // init all text objects
        this.CreateText(nameDisplay, addressDisplay, adjectiveDisplay, hardSkills, hardSkillsLabels, softSkills, roles, careerInterestDisplay, educationList, workList, workDict, languages, licenses, profOrg, volunteerOrg, interest, otherSkills, points, profSummary);

        // setup document
        this.#document = new DocxBuilder();

        // add name and address
        this.#document
            .AddParagraph(this.nameDisplay)
            .AddParagraph(this.addressDisplay);

        // add professional summary
        if (!disp.IsNullorWhitespace(this.profSum)) {
            this.#document
                .AddParagraph("PROFESSIONAL SUMMARY")
                .AddParagraph(this.profSum);
        }

        // add skills
        if (!(this.skillHeaders === null || this.skillHeaders.length == 0)) {
            let skillText = super.ReportFormatTwoLists(this.skillHeaders, this.skillValues).reduce((a, c) => `${a} ${c}`);
            skillText = super.CleanFormatting(skillText);

            this.#document
                .AddParagraph("SKILLS AND ABILITIES")
                .AddParagraph(skillText);
        }

        // Work
        if (workList.length > 0) {
            this.#document.AddParagraph("EXPERIENCE");
            workList.forEach(work => {
                let firstWorkRole = work.roles.length > 0 ? work.roles[0] : "";

                this.#document
                    .AddParagraph(`${work.general} ${work.location}`)
                    .AddParagraph(`${firstWorkRole} ${work.date}`);

                if (work.roles.length > 1) {
                    for (let i = 1; i < work.roles.length; i++) {
                        this.#document.AddParagraph(work.roles[i]);
                    }
                }

                [work.responsibilities, ...work.achievements].forEach(ach => {
                    this.#document
                        .AddParagraph(ach);
                });
            });
        }

        // Education
        if (educationList.length > 0) {
            this.#document.AddParagraph("EDUCATION");

            educationList.forEach(edu => {
                this.#document
                    .AddParagraph(`${edu.general} ${edu.location}`)
                    .AddParagraph(`${edu.degree} ${edu.date}`);

                let eduLines = [edu.expectedGrad, edu.degreeMinor, edu.gpa, ...edu.accolades].filter(i => !disp.IsNullorWhitespace(i));
                eduLines.forEach(l => {
                    this.#document.AddParagraph(l);
                })
            });
        }

        // Add additional items
        if (this.addArr.length > 0) {
            this.#document
                .AddParagraph("ADDITIONAL INFORMATION");

            this.addArr.forEach(add => {
                this.#document
                    .AddParagraph(add);
            })
        }

        // return blob
        return this.#document.BuildSection().GetBlob();

    }

    BuildReport(nameDisplay, addressDisplay, adjectiveDisplay, hardSkills, hardSkillsLabels, softSkills, roles, careerInterestDisplay, educationList, workList, workDict, languages, licenses, profOrg, volunteerOrg, interest, otherSkills, points, profSummary) {

        // init all text
        this.CreateText(nameDisplay, addressDisplay, adjectiveDisplay, hardSkills, hardSkillsLabels, softSkills, roles, careerInterestDisplay, educationList, workList, workDict, languages, licenses, profOrg, volunteerOrg, interest, otherSkills, points, profSummary);

        this.#request = new ReportRequest();
        this.#request
            .AddLine("name", this.nameDisplay)
            .AddLine("address", this.addressDisplay);

        // professional summary
        if (!disp.IsNullorWhitespace(this.profSum)) {
            this.#request.AddLine("profSummary", this.profSum)
        } else {
            this.#request.AddLine("profSummary", "")
                .AddLine("profsummarytitle", "");
        }

        // add skills
        let skillText = super.ReportFormatTwoLists(this.skillHeaders, this.skillValues).reduce((a, c) => `${a} ${c}`, "");
        skillText = super.CleanFormatting(skillText);

        if (!disp.IsNullorWhitespace(skillText)) {
            this.#request.AddLine("skills", skillText);
        } else {
            this.#request.AddLine("skills", "")
                .AddLine("skillsTitle", "");
        }


        // Work
        if (workList.length > 0) {
            this.#request.StartRepeat("repeatWork");
            workList.forEach(work => {
                this.#request
                    .StartRepeatItem()
                    .AddLine("workCompany", work.general)
                    .AddLine("workLocation", work.location)
                    .StartRepeat("repeatWorkTitle");

                work.roles.forEach(r => {
                    this.#request.StartRepeatItem()
                        .AddLine("workTitle", r)
                        .EndRepeatItem()
                });

                this.#request.EndRepeat("repeatWorkTitle")
                    .AddLine("workTimeFrame", work.date)
                    .StartRepeat("repeatworkachieve");
                [work.responsibilities, ...work.achievements, work.directReports].forEach(ach => {
                    this.#request
                        .StartRepeatItem()
                        .AddLine("workachieve", ach)
                        .EndRepeatItem()
                });
                this.#request
                    .EndRepeat("repeatworkachieve")
                    .EndRepeatItem();
            });
            this.#request.EndRepeat("repeatWork");
        }
        else {
            this.#request.AddLine("workTitle", "");
        }


        // Education
        if (educationList.length > 0) {
            this.#request.StartRepeat("repeatEdu");
            educationList.forEach(edu => {
                this.#request
                    .StartRepeatItem()
                    .AddLine("eduSchool", edu.general)
                    .AddLine("eduLocation", edu.location)
                    .AddLine("eduDegree", edu.degree)
                    .AddLine("eduTimeFrame", edu.date)
                    .StartRepeat("repeateduitems");

                [edu.expectedGrad, edu.degreeMinor, edu.gpa, ...edu.accolades].forEach((acc) => {
                    this.#request
                        .StartRepeatItem()
                        .AddLine("eduitem", acc)
                        .EndRepeatItem()
                });

                this.#request
                    .EndRepeat("repeateduitems")
                    .EndRepeatItem()
            });
            this.#request.EndRepeat("repeatEdu");
        }
        else {
            this.#request.AddLine("educationTitle", "");
        }

        // Build additional items array

        if (this.addArr.length > 0) {
            this.#request.StartRepeat("repeatAdditional");
            this.addArr.forEach(add => {
                this.#request
                    .StartRepeatItem()
                    .AddLine("addLine", add)
                    .EndRepeatItem();
            })
            this.#request.EndRepeat("repeatAdditional");
        } else {
            this.#request.AddLine("additionalTitle", "");
        }

        // Return build report object
        return this.#request.Build(this.ReportName);
    }

    BuildAdditionalItems(languages, licenses, profOrg, volunteerOrg, interest, points) {
        let addArr = [];

        if (languages.length > 0) {
            let lstr = `${ReportBuilderBase.languagesLabel}: `;
            lstr += languages;
            lstr += ".";
            addArr.push(lstr);
        }

        if (licenses.length > 0) {
            let cstr = `${ReportBuilderBase.licensesLabel}: `;
            cstr += licenses;
            cstr += ".";
            addArr.push(cstr);
        }

        if (profOrg.length > 0) {
            let pstr = `${ReportBuilderBase.profOrgLabel}: `;
            pstr += profOrg;
            pstr += "."
            addArr.push(pstr);
        }

        if (volunteerOrg.length > 0) {
            let vstr = `${ReportBuilderBase.volunteerOrgLabel}: `;
            vstr += volunteerOrg;
            vstr += ".";
            addArr.push(vstr);
        }

        if (interest.length > 0) {
            let istr = `${ReportBuilderBase.interestsLabel}: `;
            istr += interest;
            istr += ".";
            addArr.push(istr);
        }

        if (points?.length > 0) {
            let pstr = `${ReportBuilderBase.otherLabel}: `;
            pstr += disp.BuildCommaString("", points);
            pstr += ".";
            addArr.push(pstr);
        }

        return addArr;
    }
}