<template>
  <v-dialog transition="dialog-top-transition" max-width="800" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" block v-bind="attrs" v-on="on">
        Select a Resume Format
      </v-btn>
    </template>

    <v-card>
      <v-system-bar
        :height="$vuetify.breakpoint.xs ? 75 : 50"
        color="primary"
        class="white--text elevation-5"
      >
        <span class="text-h6 pl-2">Select a Resume Format</span>
      </v-system-bar>
      <v-system-bar height="5" color="accent" class=""></v-system-bar>
      <v-card-text class="pt-2">
        <v-list>
          <v-list-item-group v-model="selectedItem">
            <v-list-item
              v-for="item in items"
              :key="item.id"
              @click="select(item)"
              :disabled="item.disabled"
            >
              <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                <img
                  aspect-ratio="1"
                  width="130"
                  max-width="130"
                  :src="require(`@/assets/resumes/${item.filename}`)"
                  contain
                  class="pa-1 resume-img"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <p class="text-body-1">{{ item.title }}</p>
                <p class="text-body-2 text--secondary">{{ item.desc }}</p>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// import * as disp from "../utility/display"

// @ is an alias to /src
export default {
  name: "ResumeSelector",
  data: () => ({
    items: [
      {
        type: "Professional",
        title: "Professional Resume",
        desc: "Professional resume format for early career or established professionals.",
        filename: "jd_pro_small.png",
        id: 0,
      },
      {
        type: "Student",
        title: "Student Resume",
        desc: "Resume format tailored to students, with a focus on education.",
        filename: "jd_student_small.png",
        id: 1,
      },
      {
        type: "Text",
        title: "Plain Text Resume",
        desc: "No formatting, just the text of your resume.",
        filename: "jd_plain_small.png",
        id: 2,
      },
      // {
      //   type: "Basic",
      //   title: "Basic Resume (Remove, testing)",
      //   desc: "Basic Resume.",
      //   filename: "bkg_5_500.svg",
      //   id: 3,
      // },
    ],
    selectedItem: 0,
    dialog: false,
  }),
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  mounted() {},
  watch: {
    type: {
      handler() {
        let selected = this.items.find((i) => i.type === this.type);
        this.selectedItem = selected?.id;

        this.updateSelected(selected);
      },
    },
  },
  computed: {},
  methods: {
    select(selected) {
      this.selectedItem = selected;
      this.dialog = false;
      this.updateSelected(selected);
    },
    updateSelected(selected) {
      if (selected) {
        this.$emit("update:type", selected.type);
        this.$emit("update:title", selected.title);
        this.$emit("update:desc", selected.desc);
      }
    },
  },
};
</script>


<style scoped>
.resume-img {
  background-color: var(--v-resumeBackground-base);
  z-index: 1;
}
</style>