<template>
  <page-main title="Resume Review" noBottom :error="error">
    <premium-banner v-model="showPremiumBanner" />
    <template v-slot:aside>
      Ok! Let's take a look at your resume!
      <ul>
        <li>Review your resume, then download in the format you need.</li>
        <li>
          Resumation Plus users can customize their resume by using the toggles
          below - toggle the parts of your resume you want to show/hide.
        </li>
        <li>
          Need to make an edit to your resume content? No problem - go back to
          the section and make the edit(s), and when you return to this page your edits will be updated.
        </li>
      </ul>
    </template>
    <template v-slot:nav>
      <v-container style="position: relative" class="unstyled-list">
        <v-btn
          class="reset-btn"
          color="primary"
          outlined
          text
          @click="resetCustomizations(customizations)"
        >
          Reset All Custom Selections
        </v-btn>
        <r-expansion-panels>
          <r-expansion-panel :readonly="true">
            <v-expansion-panel-header
              color="primary"
              class="white--text elevation-5 rounded-0"
            >
              <template #actions> &nbsp; </template>
              <div class="d-flex align-center">
                <div class="pr-1">
                  <s-checkbox
                    class="customization-checkbox"
                    color="white"
                    :disabled="!isPremium"
                    v-model="customizations.professionalSummary.checked"
                  >
                  </s-checkbox>
                </div>
                <div class="pr-4">Professional Summary</div>
              </div>
            </v-expansion-panel-header>
          </r-expansion-panel>
          <r-expansion-panel>
            <v-expansion-panel-header
              color="primary"
              class="white--text elevation-5 rounded-0"
            >
              <div class="d-flex align-center">
                <div class="pr-1">
                  <s-checkbox
                    class="customization-checkbox"
                    color="white"
                    :disabled="!isPremium"
                    v-model="customizations.experience.checked"
                    @click="refreshExperienceChildren()"
                  >
                  </s-checkbox>
                </div>
                <div class="pr-4">Experience</div>
              </div>
            </v-expansion-panel-header>
            <r-expansion-panel-content>
              <ul>
                <li
                  v-for="item in customizations.experience.workList"
                  :key="item.key"
                >
                  <r-expansion-panels>
                    <r-expansion-panel>
                      <v-expansion-panel-header
                        color="#414141"
                        class="white--text elevation-5 rounded-0"
                      >
                        <div class="d-flex align-center">
                          <div class="pr-1">
                            <s-checkbox
                              class="customization-checkbox"
                              color="white"
                              :disabled="!isPremium"
                              v-model="item.checked"
                              @click="
                                refreshWorkChildrenAndExperienceParent(item)
                              "
                            >
                            </s-checkbox>
                          </div>
                          <div class="pr-4">{{ item.label }}</div>
                        </div>
                      </v-expansion-panel-header>
                      <r-expansion-panel-content>
                        <ul>
                          <li v-if="item.responsibilities.label.length !== 0">
                            <div class="d-flex align-center">
                              <div class="pr-1">
                                <s-checkbox
                                  class="customization-checkbox"
                                  color="#084492"
                                  :disabled="!isPremium"
                                  v-model="item.responsibilities.checked"
                                  @click="
                                    refreshWorkResponsibilitiesParents(item)
                                  "
                                >
                                </s-checkbox>
                              </div>
                              <div class="pr-4">
                                <p>
                                  <span class="font-weight-bold"
                                    >Responsibilities:</span
                                  >
                                  {{ item.responsibilities.label }}
                                </p>
                              </div>
                            </div>
                          </li>
                          <li v-if="item.achievements.list.length !== 0">
                            <div class="d-flex align-center">
                              <div class="pr-1">
                                <s-checkbox
                                  class="customization-checkbox"
                                  color="#084492"
                                  :disabled="!isPremium"
                                  v-model="item.achievements.checked"
                                  @click="
                                    refreshWorkAchievementsChildrenAndParents(
                                      item
                                    )
                                  "
                                >
                                </s-checkbox>
                              </div>
                              <div class="pr-4">
                                <p>
                                  <span class="font-weight-bold"
                                    >Achievements:</span
                                  >
                                </p>
                              </div>
                            </div>
                            <ul>
                              <li
                                v-for="workAchievements in item.achievements
                                  .list"
                                :key="workAchievements.label"
                              >
                                <div class="d-flex align-center">
                                  <div class="pr-1">
                                    <s-checkbox
                                      class="customization-checkbox"
                                      color="#084492"
                                      :disabled="!isPremium"
                                      v-model="workAchievements.checked"
                                      @click="
                                        refreshWorkAchievementsParents(
                                          item,
                                          workAchievements
                                        )
                                      "
                                    >
                                    </s-checkbox>
                                  </div>
                                  <div class="pr-4">
                                    {{ workAchievements.label }}
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </r-expansion-panel-content>
                    </r-expansion-panel>
                  </r-expansion-panels>
                </li>
              </ul>
            </r-expansion-panel-content>
          </r-expansion-panel>
          <r-expansion-panel>
            <v-expansion-panel-header
              color="primary"
              class="white--text elevation-5 rounded-0"
            >
              <div class="d-flex align-center">
                <div class="pr-1">
                  <s-checkbox
                    class="customization-checkbox"
                    color="white"
                    :disabled="!isPremium"
                    v-model="customizations.education.checked"
                    @click="refreshEducationChildren()"
                  >
                  </s-checkbox>
                </div>
                <div class="pr-4">Education</div>
              </div>
            </v-expansion-panel-header>
            <r-expansion-panel-content>
              <ul>
                <li
                  v-for="item in customizations.education.educationList"
                  :key="item.key"
                >
                  <r-expansion-panels>
                    <r-expansion-panel>
                      <v-expansion-panel-header
                        color="#414141"
                        class="white--text elevation-5 rounded-0"
                      >
                        <div class="d-flex align-center">
                          <div class="pr-1">
                            <s-checkbox
                              class="customization-checkbox"
                              color="white"
                              :disabled="!isPremium"
                              v-model="item.checked"
                              @click="refreshEducationChildrenAndParent(item)"
                            >
                            </s-checkbox>
                          </div>
                          <div class="pr-4">{{ item.label }}</div>
                        </div>
                      </v-expansion-panel-header>
                      <r-expansion-panel-content>
                        <ul>
                          <li v-if="item.gpa">
                            <div class="d-flex align-center">
                              <div class="pr-1">
                                <s-checkbox
                                  class="customization-checkbox"
                                  color="#084492"
                                  :disabled="!isPremium"
                                  v-model="item.gpa.checked"
                                  @click="
                                    refreshEducationAccoladesParents(
                                      item,
                                      item.gpa
                                    )
                                  "
                                >
                                </s-checkbox>
                              </div>
                              <div class="pr-4">{{ item.gpa.label }}</div>
                            </div>
                          </li>
                          <li
                            v-for="accolade in item.accolades"
                            :key="accolade.label"
                          >
                            <div class="d-flex align-center">
                              <div class="pr-1">
                                <s-checkbox
                                  class="customization-checkbox"
                                  color="#084492"
                                  :disabled="!isPremium"
                                  v-model="accolade.checked"
                                  @click="
                                    refreshEducationAccoladesParents(
                                      item,
                                      accolade
                                    )
                                  "
                                >
                                </s-checkbox>
                              </div>
                              <div class="pr-4">{{ accolade.label }}</div>
                            </div>
                          </li>
                        </ul>
                      </r-expansion-panel-content>
                    </r-expansion-panel>
                  </r-expansion-panels>
                </li>
              </ul>
            </r-expansion-panel-content>
          </r-expansion-panel>
          <r-expansion-panel>
            <v-expansion-panel-header
              color="primary"
              class="white--text elevation-5 rounded-0"
            >
              <div class="d-flex align-center">
                <div class="pr-1">
                  <s-checkbox
                    class="customization-checkbox"
                    :disabled="!isPremium"
                    color="white"
                    v-model="customizations.skills.checked"
                    @click="refreshSkillsChildren()"
                  >
                  </s-checkbox>
                </div>
                <div class="pr-4">Skills and Abilities</div>
              </div>
            </v-expansion-panel-header>
            <r-expansion-panel-content>
              <ul>
                <li
                  v-for="item in customizations.skills.skillList"
                  :key="item.label"
                >
                  <div class="d-flex align-center">
                    <div class="pr-1">
                      <s-checkbox
                        class="customization-checkbox"
                        color="#084492"
                        :disabled="!isPremium"
                        v-model="item.checked"
                        @click="refreshSkillsParent(item)"
                      >
                      </s-checkbox>
                    </div>
                    <div class="pr-4">{{ item.label }}</div>
                  </div>
                </li>
              </ul>
            </r-expansion-panel-content>
          </r-expansion-panel>
          <r-expansion-panel>
            <v-expansion-panel-header
              color="primary"
              class="white--text elevation-5 rounded-0"
            >
              <div class="d-flex align-center">
                <div class="pr-1">
                  <s-checkbox
                    class="customization-checkbox"
                    color="white"
                    :disabled="!isPremium"
                    v-model="customizations.additionalInfo.checked"
                    @click="refreshAdditionalInfoChildren()"
                  >
                  </s-checkbox>
                </div>
                <div class="pr-4">Additional Information</div>
              </div>
            </v-expansion-panel-header>
            <r-expansion-panel-content>
              <ul>
                <li
                  v-for="item in customizations.additionalInfo
                    .additionalInfoList"
                  :key="item.label"
                >
                  <div class="d-flex align-center">
                    <div class="pr-1">
                      <s-checkbox
                        class="customization-checkbox"
                        color="#084492"
                        :disabled="!isPremium"
                        v-model="item.checked"
                        @click="refreshadditionalInfoParent(item)"
                      >
                      </s-checkbox>
                    </div>
                    <div class="pr-4">{{ item.label }}</div>
                  </div>
                </li>
              </ul>
            </r-expansion-panel-content>
          </r-expansion-panel>
        </r-expansion-panels>
      </v-container>
    </template>
    <v-container style="position: relative">
      <v-overlay :absolute="true" v-if="overlay">
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-row class="pb-3" justify="center">
        <v-col cols="12" sm="5" md="4" lg="4">
          <div>
            <p class="text-h6 mb-2">{{ resumeTitle }}</p>
            <p class="text-body-2 text--secondary font-italic mb-0">
              {{ resumeDesc }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="5" md="5" lg="4">
          <resume-selector
            :type.sync="resumeType"
            @update:title="updateTitle($event)"
            @update:desc="updateDesc($event)"
          ></resume-selector>
          <v-btn
            block
            outlined
            @click="downloadPdf()"
            id="download-pdf"
            class="mt-3"
            >Download PDF</v-btn
          >
          <v-btn
            block
            outlined
            @click="downloadWord()"
            id="download-word"
            class="mt-3"
            >Download Word (.docx)</v-btn
          >
          <v-btn
            block
            outlined
            @click="showSaveDialog()"
            id="save-resume"
            class="mt-3"
          >
            Save Resume
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <div v-if="errorOnLoad">
            <div
              class="d-flex flex-column justify-center align-center error-box"
            >
              <div class="pb-5 error-box-content">
                <h1>Ouch!</h1>
                <h2>We couldn't load your resume!</h2>
              </div>
              <div class="error-box-content">
                <div class="font-italic pb-2">
                  We're looking into the problem - in the meantime you can try
                  reloading the page:
                </div>
                <v-btn @click="refresh()" outlined>Refresh</v-btn>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="resume-backing pa-3 mb-4 pb-1">
              <img
                v-if="resumeImageSrc.length > 0"
                :src="resumeImageSrc"
                class="resume-img"
                alt="Resume View"
              />
              <div v-else class="resume-placeholder"></div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <r-dialog
      v-model="saveDialog"
      title="Save Resume"
      persistent
      transition="dialog-top-transition"
    >
      <v-overlay :absolute="true" v-if="saveOverlay">
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <p class="text-body-1 text--primary mb-0">
          Save your resume to your account for easy access later.
        </p>
        <v-text-field
          label="Give a name to this version of your Resume"
          v-model="saveResumeName"
          :rules="nameRules"
        ></v-text-field>
        <v-textarea
          label="Notes for this version (Optional)"
          v-model="saveResumeNote"
          rows="2"
          auto-grow
        ></v-textarea>
        <v-text-field
          disabled
          label="Date Saved"
          v-model="curDate"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between align-end pb-3">
        <v-btn color="gray" outlined @click="saveDialog = false">Cancel</v-btn>
        <v-btn color="primary" outlined @click="saveResume">Save</v-btn>
      </v-card-actions>
    </r-dialog>
    <r-dialog
      v-model="noEmailDialog"
      title="Please Confirm Your Email"
      persistent
    >
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <p class="text-body-1 text--primary mb-0">
          You must confirm your email address to proceed.
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between align-end pb-3">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            class="d-flex justify-center justify-sm-start"
          >
            <div class="d-flex justify-center justify-sm-start flex-column">
              <div>
                <p class="text-body-2 text--secondary font-italic mb-0">
                  Already confirmed your email?
                </p>
              </div>
              <v-btn color="gray" outlined @click="checkStatus"
                >Check Status</v-btn
              >
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="align-self-end d-flex justify-center justify-sm-end"
          >
            <v-btn
              color="primary"
              :disabled="!confirmEmailEnabled"
              :loading="confirmEmailLoading"
              outlined
              @click="sendConfirmEmail"
              >{{ confirmEmailButtonText }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </r-dialog>
  </page-main>
</template>

<script>
import * as log from "../utility/logging/logger";
import { mapGetters, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import DataAccess from "../report/DataAccess.js";
import * as ReportAPI from "../api/ReportApi.js";
import ReportBuilder from "../reportBuilder/ReportBuilder";
import ReportBuilderBase from "../reportBuilder/ReportBuilderBase";
import ResumeSelector from "../components/ResumeSelector.vue";
import ShortCheckBox from "../components/ShortCheckbox.vue";
import * as login from "../api/LoginApi";
import PremiumBanner from "../components/PremiumBanner.vue";
import * as careerApi from "../api/CareerApiUser";
import { required } from "../utility/validation.js";

// @ is an alias to /src
export default {
  name: "Review2",
  components: {
    "resume-selector": ResumeSelector,
    "s-checkbox": ShortCheckBox,
    "premium-banner": PremiumBanner,
  },
  data: () => ({
    customizations: {
      professionalSummary: { checked: true },
      experience: { checked: true, workList: [] },
      education: { checked: true, educationList: [] },
      skills: { checked: true, skillList: [] },
      additionalInfo: {
        checked: true,
        additionalInfoList: [
          { checked: true, label: ReportBuilderBase.softSkillsLabels},
          { checked: true, label: ReportBuilderBase.rolesLabel},
          { checked: true, label: ReportBuilderBase.languagesLabel },
          { checked: true, label: ReportBuilderBase.licensesLabel },
          { checked: true, label: ReportBuilderBase.profOrgLabel },
          { checked: true, label: ReportBuilderBase.volunteerOrgLabel },
          { checked: true, label: ReportBuilderBase.interestsLabel },
          { checked: true, label: ReportBuilderBase.otherLabel },
        ],
      },
    },
    fileName: "Resume",
    education: [],
    workExp: [],
    hardSkillsDisp: "",
    volunteerDisp: "",
    professionalOrgDisp: "",
    professionalCertDisp: "",
    interestsDisp: "",
    renderReport: {},
    reportRequest: {},
    builder: {},
    token: "",
    dateExpires: {},
    overlay: true,
    errorOnLoad: false,
    error: "",
    resumeType: "",
    imgData: "",
    resumeTitle: "",
    resumeDesc: "",
    noEmailDialog: false,
    confirmEmailButtonText: "Re-Send Confirmation",
    confirmEmailLoading: false,
    confirmEmailEnabled: true,
    showPremiumBanner: false,
    saveDialog: false,
    saveResumeName: "",
    saveResumeNote: "",
    nameRules: [(value) => required(value)],
    curDate: "",
    saveOverlay: false,
  }),
  async mounted() {
    this.curDate = new Date().toLocaleDateString("en-us");
    this.dateExpires = new Date(new Date().getTime() + -60 * 1000); // set to one minute ago to init
    this.resumeType = "Professional"; // triggers watch on resumeType
    this.setupCustomizations();
  },
  computed: {
    ...mapFields("name", { nameDisplay: "displayValue" }),
    ...mapFields("address", { addressDisplay: "displayValue" }),
    ...mapFields("hardSkill", ["hardSkills", "hardSkillLabels"]),
    ...mapFields("softSkill", ["softSkills"]),
    ...mapFields("licenseCert", ["licensesCertsDisplay"]),
    ...mapFields("adjective", ["selectedAdjectives", "adjectiveDisplay"]),
    ...mapFields("role", ["selectedRoles"]),
    ...mapFields("language", ["languagesDisplay"]),
    ...mapFields("organization", [
      "volunteerOrgDisplay",
      "professionalOrgDisplay",
    ]),
    ...mapFields("personalInterest", ["personalInterestsDisplay"]),
    ...mapFields("careerInterest", ["careerInterestsDisplay"]),
    ...mapFields("work", ["workDict"]),
    ...mapFields("education", ["educationDict"]),
    ...mapFields("settings", ["reportURL"]),
    ...mapFields("finalInfo", ["otherSkills", "points", "professionalSummary"]),
    ...mapGetters("education", { eduList: "fullList" }),
    ...mapGetters("work", { workList: "fullList" }),
    ...mapFields("auth", {
      emailConfirmed: "emailConfirmed",
      authType: "auth.authType",
      isPremium: "isPremium",
    }),
    canProceedEmail() {
      return this.authType != "resumation" || this.emailConfirmed;
    },
    resumeImageSrc() {
      if (this.imgData.length === 0) {
        return "";
      } else {
        return this.imgData;
      }
    },
  },
  watch: {
    resumeType: {
      async handler() {
        await this.initReportBuilder(this.resumeType);
      },
    },
    customizations: {
      async handler() {
        await this.initReportBuilder(this.resumeType);
        if (!this.isPremium) {
          const checkboxes = document.querySelectorAll(
            ".customization-checkbox"
          );
          checkboxes.forEach((checkbox) => {
            checkbox.removeEventListener("click", this.openPremiumBanner);
            checkbox.addEventListener("click", this.openPremiumBanner);
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("auth", ["logUserIn"]),
    updateDesc(desc) {
      this.resumeDesc = desc;
    },
    updateTitle(title) {
      this.resumeTitle = title;
    },
    async sendConfirmEmail() {
      this.confirmEmailLoading = true;
      this.confirmEmailEnabled = false;

      const confirmEmailResp = await login.SendConfirmEmail();
      if (!confirmEmailResp.success) {
        this.emailErrorText = "Unable to send a confirmation email.";
        this.confirmEmailEnabled = true;
      } else {
        this.confirmEmailButtonText = "Confirmation Email Sent";
        this.confirmEmailEnabled = false;
      }
      this.confirmEmailLoading = false;
    },
    async checkStatus() {
      await this.initReportBuilder(this.resumeType);
    },
    async initReportBuilder(resumeType) {
      try {
        this.overlay = true;
        this.errorOnLoad = false;

        if (!this.canProceedEmail) {
          await this.logUserIn();
          this.noEmailDialog = !this.canProceedEmail;
          if (this.noEmailDialog) {
            return;
          }
        }

        // Get Token
        let da = new DataAccess(this.reportURL);
        let token = await this.getToken();
        if (token === null) {
          log.logError(
            "Token was not retrieved successfully!",
            "Review.initReportBuilder"
          );
          this.errorOnLoad = true;
          this.overlay = false;
          return;
        }

        // Setup reportBuilder, build reportRequest object
        this.buildReportRequest(resumeType);

        // Retrieve image from report server
        let image = await da.GetReportPNG(this.reportRequest, token);
        this.imgData = URL.createObjectURL(image);

        this.overlay = false;
      } catch (error) {
        log.logError(
          "Unhandled error in initReportBuilder code",
          "Review.initReportBuilder"
        );
        log.logError(`${error.name}: ${error.message}`);
        this.errorOnLoad = true;
        this.overlay = false;
      }
    },
    refresh() {
      log.logWarning(
        "User triggered refresh of Resume page due to error.",
        "Review.refresh"
      );
      window.location.href = "/Review";
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    buildReportRequest(resumeType) {
      this.builder = new ReportBuilder(resumeType, this.customizations);

      this.reportRequest = this.builder.BuildReport(
        this.nameDisplay,
        this.addressDisplay,
        this.adjectiveDisplay,
        this.hardSkills,
        this.hardSkillLabels,
        this.softSkills,
        this.selectedRoles,
        this.careerInterestsDisplay,
        this.eduList,
        this.workList,
        this.workDict,
        this.languagesDisplay,
        this.licensesCertsDisplay,
        this.professionalOrgDisplay,
        this.volunteerOrgDisplay,
        this.personalInterestsDisplay,
        this.otherSkills,
        this.points,
        this.professionalSummary
      );
    },
    async getToken() {
      if (new Date() > this.dateExpires) {
        var tokenResult = await ReportAPI.GetReportToken();
        if (tokenResult.success) {
          this.token = tokenResult.data.access_token;
          this.dateExpires = new Date(
            new Date().getTime() + tokenResult.data.expires_in * 1000
          );
          return this.token;
        } else {
          return null;
        }
      } else {
        return this.token;
      }
    },
    async downloadPdf() {
      try {
        let da = new DataAccess(this.reportURL);

        var token = await this.getToken();
        if (token === null) {
          log.logError(
            "Token was not retrieved successfully!",
            "Review.downloadPdf"
          );
          this.error =
            "Ouch! We had an unexpected error while downloading your Resume.";
          return;
        }

        let report = await da.GetReportPDF(this.reportRequest, token);
        this.downloadFile(report, `${this.nameDisplay} Resume.pdf`);
      } catch (error) {
        log.logError(
          "Unhandled error while downloading pdf",
          "Review.downloadPdf"
        );
        this.error =
          "Ouch! We had an unexpected error while downloading your Resume.";
      }
    },
    // todo: move to common method
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
          resolve(reader.result.slice(reader.result.indexOf("base64,", 0) + 7));
        reader.onerror = (error) => reject(error);
      });
    },
    buildFileName(extension) {
      return `${this.nameDisplay} Resume.${extension}`;
    },
    showSaveDialog() {
      if (this.isPremium) {
        this.saveDialog = true;
      } else {
        this.openPremiumBanner();
      }
    },
    async saveResume() {
      try {
        this.saveOverlay = true;

        // build report?
        let report = await this.buildDocx();
        var reportBase64 = await this.toBase64(report);

        // send to endpoint?
        var result = await careerApi.CreateReview(
          this.saveResumeName,
          this.saveResumeNote,
          reportBase64,
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          this.buildFileName("docx")
        );
        if (!result.success) {
          this.error =
            "An unexpected error occured while saving your resume. Please refresh and try again.";
        } else {
          this.saveOverlay = false;
          this.nav("/SavedResumes");
        }
      } catch (err) {
        log.logError("Unhandled error in saveResume", "Review.saveResume");
        log.logError(`${err.name}: ${err.message}`);
      } finally {
        this.saveOverlay = false;
        this.saveDialog = false;
      }
    },
    async downloadWord() {
      let report = await this.buildDocx();
      this.downloadFile(report, `${this.nameDisplay} Resume.docx`);
    },
    async buildDocx() {
      return await this.builder.BuildDocx(
        this.nameDisplay,
        this.addressDisplay,
        this.adjectiveDisplay,
        this.hardSkills,
        this.hardSkillLabels,
        this.softSkills,
        this.selectedRoles,
        this.careerInterestsDisplay,
        this.eduList,
        this.workList,
        this.workDict,
        this.languagesDisplay,
        this.licensesCertsDisplay,
        this.professionalOrgDisplay,
        this.volunteerOrgDisplay,
        this.personalInterestsDisplay,
        this.otherSkills,
        this.points,
        this.professionalSummary
      );
    },
    downloadFile(blob, fileName) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.append(link);
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    },
    downloadText() {
      alert("Coming soon...");
    },
    refreshExperienceChildren() {
      const checked = !!this.customizations.experience.checked;
      this.customizations.experience.workList.forEach((workElement) => {
        workElement.checked = checked;
        workElement.responsibilities.checked = checked;
        workElement.achievements.checked = checked;
        workElement.achievements.list.forEach(
          (achievement) => (achievement.checked = checked)
        );
      });
    },
    refreshWorkChildrenAndExperienceParent(workItem) {
      if (workItem.checked) {
        this.customizations.experience.checked = true;
      }
      workItem.responsibilities.checked = workItem.checked;
      workItem.achievements.checked = workItem.checked;
      workItem.achievements.list.forEach(
        (achievement) => (achievement.checked = workItem.checked)
      );
    },
    refreshWorkAchievementsChildrenAndParents(workItem) {
      if (workItem.achievements.checked) {
        workItem.checked = true;
        this.customizations.experience.checked = true;
      }
      workItem.achievements.list.forEach(
        (achievement) => (achievement.checked = workItem.achievements.checked)
      );
    },
    refreshWorkResponsibilitiesParents(workItem) {
      if (workItem.responsibilities.checked) {
        workItem.checked = true;
        this.customizations.experience.checked = true;
      }
    },
    refreshWorkAchievementsParents(workItem, achievementItem) {
      if (achievementItem.checked) {
        workItem.checked = true;
        workItem.achievements.checked = true;
        this.customizations.experience.checked = true;
      }
    },
    refreshEducationChildren() {
      const checked = !!this.customizations.education.checked;
      this.customizations.education.educationList.forEach((eduElement) => {
        eduElement.checked = checked;
        eduElement.accolades.forEach(
          (accolade) => (accolade.checked = checked)
        );
      });
    },
    refreshEducationChildrenAndParent(eduItem) {
      if (eduItem.checked) {
        this.customizations.education.checked = true;
      }
      eduItem.accolades.forEach((accoladeElement) => {
        accoladeElement.checked = eduItem.checked;
      });
    },
    refreshEducationAccoladesParents(item, accolade) {
      if (accolade.checked) {
        item.checked = true;
        this.customizations.education.checked = true;
      }
    },
    refreshSkillsParent(skillItem) {
      if (skillItem.checked) {
        this.customizations.skills.checked = true;
      }
    },
    refreshSkillsChildren() {
      const checked = !!this.customizations.skills.checked;
      this.customizations.skills.skillList.forEach((skillElement) => {
        skillElement.checked = checked;
      });
    },
    refreshAdditionalInfoChildren() {
      const checked = !!this.customizations.additionalInfo.checked;
      this.customizations.additionalInfo.additionalInfoList.forEach(
        (addInfoElement) => {
          addInfoElement.checked = checked;
        }
      );
    },
    refreshadditionalInfoParent(addInfoItem) {
      if (addInfoItem.checked) {
        this.customizations.additionalInfo.checked = true;
      }
    },
    resetCustomizations(customizations) {
      Object.keys(customizations).forEach((e) => {
        if (e === "checked") {
          customizations[e] = true;
        } else if (Array.isArray(customizations[e])) {
          customizations[e].forEach((a) => this.resetCustomizations(a));
        } else if (typeof customizations[e] === "object") {
          this.resetCustomizations(customizations[e]);
        }
      });
    },
    async setupCustomizations() {
      if (!this.isPremium) {
        await this.logUserIn();
      }
      this.setupWorkCustomizations();
      this.setupEducationCustomizations();
      this.setupSkillsCustomizations();
    },
    openPremiumBanner() {
      this.showPremiumBanner = true;
    },
    setupSkillsCustomizations() {
      this.hardSkillLabels.forEach((hsl) => {
        this.customizations.skills.skillList.push({
          checked: true,
          level: hsl.level,
          label: hsl.text,
        });
      });
      this.customizations.skills.skillList.push({
        checked: true,
        level: "other",
        label: "Other Skills",
      });
    },
    setupEducationCustomizations() {
      this.eduList.forEach((eduElement) => {
        let educationItem = {
          checked: true,
          key: eduElement.key,
          label: eduElement.general,
          accolades: eduElement.accolades.map((accolade) => {
            return { checked: true, label: accolade };
          }),
        };
        if (eduElement.gpa) {
          educationItem = {
            ...educationItem,
            gpa: { label: eduElement.gpa, checked: true },
          };
        }
        this.customizations.education.educationList.push(educationItem);
      });
    },
    setupWorkCustomizations() {
      this.workList.forEach((workElement) => {
        const label = `${workElement.general}: ${workElement.roles.join("; ")}`;
        this.customizations.experience.workList.push({
          checked: true,
          label: label,
          key: workElement.key,
          responsibilities: {
            checked: true,
            label: workElement.responsibilities,
          },
          achievements: {
            checked: true,
            list: workElement.achievements.map((e) => {
              return { checked: true, label: e };
            }),
          },
        });
      });
    },
  },
};
</script>

<style scoped>
.error-box {
  width: 636px;
  height: 816px;
  background-color: var(--v-resumeErrorBox-base);
  color: var(--v-resumeErrorText-base);
}

.error-box-content {
  width: 70%;
}

.resume-placeholder {
  width: 612px;
  height: 792px;
  background-color: var(--v-resumePlaceholder-base);
}

.resume-backing {
  background-color: var(--v-resumeBacker-base);
  max-width: 636px;
}

.resume-img {
  max-width: 100%;
  height: auto;
}

.unstyled-list ul {
  margin-right: -24px;
  margin-top: 12px;
  padding: 10px;
}

.unstyled-list li {
  list-style: none;
  margin-bottom: 20px;
}

.unstyled-list li:last-child {
  margin-bottom: 0px;
}

.unstyled-list p {
  margin: 0;
}

.unstyled-list .v-input {
  margin: 0;
}

.reset-btn {
  margin-bottom: 10px;
}
</style>