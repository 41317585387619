import ReportBuilderProfessional from "./ReportBuilderProfessional";
import ReportBuilderStudent from "./ReportBuilderStudent";
import ReportBuilderText from "./ReportBuilderText";
import ReportBuilderException from "./ReportBuilderException";

export default class ReportBuilder {

    ReportType = "";
    SupportedTypes = ["Professional", "Student", "Text"];

    constructor(reportType, customizations = {}) {
        if (this.SupportedTypes.filter(i => i === reportType).length === 1) {
            this.ReportType = reportType;
            switch (reportType) {
                case "Professional":
                    {
                        this.ReportBuilder = new ReportBuilderProfessional(customizations);
                        break;
                    }
                case "Student":
                    {
                        this.ReportBuilder = new ReportBuilderStudent(customizations);
                        break;
                    }
                case "Text":
                    {
                        this.ReportBuilder = new ReportBuilderText(customizations);
                        break;
                    }
            }
        } else {
            throw new ReportBuilderException(`The argument ${reportType} was not in the list of valid report types.`);
        }
    }

    BuildReport(nameDisplay, addressDisplay, adjectiveDisplay, hardSkills, hardSkillsLabels, softSkills, roles, careerInterestDisplay, educationList, workList, workDict, languages, licenses, profOrg, volunteerOrg, interest, otherSkills, points, professionalSummary) {

        if (this.ReportType === "") {
            throw new ReportBuilderException(`The initial report type is not in the list of valid report types.`);
        }
        const filteredWorkList = this.ReportBuilder.FilterWorkList(workList);        
        const filteredEducationList = this.ReportBuilder.FilterEducationList(educationList);        
        const [filteredHardSkills, filteredOtherSkills] = this.ReportBuilder.FilterSkills(hardSkills, otherSkills);        
        const [filteredLanguages, filteredLicenses, filteredProfOrg, filteredVolunteerOrg, filteredInterest, filteredPoints, filteredSoftSkills, filteredRoles] = this.ReportBuilder.FilterAdditionalInfo(languages, licenses, profOrg, volunteerOrg, interest, points, softSkills, roles);    
        const filteredProfessionalSummary = this.ReportBuilder.filterProfessionalSummary(professionalSummary);    
        const report = this.ReportBuilder.BuildReport(nameDisplay, addressDisplay, adjectiveDisplay, filteredHardSkills, hardSkillsLabels, filteredSoftSkills, filteredRoles, careerInterestDisplay, filteredEducationList, filteredWorkList, workDict, filteredLanguages, filteredLicenses, filteredProfOrg, filteredVolunteerOrg, filteredInterest, filteredOtherSkills, filteredPoints, filteredProfessionalSummary);
        return report;
    }

    // returns a blob
    BuildDocx(nameDisplay, addressDisplay, adjectiveDisplay, hardSkills, hardSkillsLabels, softSkills, roles, careerInterestDisplay, educationList, workList, workDict, languages, licenses, profOrg, volunteerOrg, interest, otherSkills, points, professionalSummary) {
        if (this.ReportType === "") {
            throw new ReportBuilderException(`The initial report type is not in the list of valid report types.`);
        }

        const filteredWorkList = this.ReportBuilder.FilterWorkList(workList);        
        const filteredEducationList = this.ReportBuilder.FilterEducationList(educationList);
        const [filteredHardSkills, filteredOtherSkills] = this.ReportBuilder.FilterSkills(hardSkills, otherSkills);                
        const [filteredLanguages, filteredLicenses, filteredProfOrg, filteredVolunteerOrg, filteredInterest, filteredPoints, filteredSoftSkills, filteredRoles] = this.ReportBuilder.FilterAdditionalInfo(languages, licenses, profOrg, volunteerOrg, interest, points, softSkills, roles);      
        const filteredProfessionalSummary = this.ReportBuilder.filterProfessionalSummary(professionalSummary);    

        return this.ReportBuilder.BuildDocx(nameDisplay, addressDisplay, adjectiveDisplay, filteredHardSkills, hardSkillsLabels, filteredSoftSkills, filteredRoles, careerInterestDisplay, filteredEducationList, filteredWorkList, workDict, filteredLanguages, filteredLicenses, filteredProfOrg, filteredVolunteerOrg, filteredInterest, filteredOtherSkills, filteredPoints, filteredProfessionalSummary);
    }

    ReportName() {
        if (this.ReportType === "") {
            throw new ReportBuilderException(`The initial report type is not in the list of valid report types.`);
        }

        return this.ReportBuilder.ReportName;
    }
}