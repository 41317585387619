<template>
  <div @click.stop="toggle" @mousedown.stop.prevent class="ma-n3 pa-3">
    <v-icon v-if="checked" :color="color">mdi-checkbox-marked</v-icon>
    <v-icon v-else :color="color">mdi-checkbox-blank-outline</v-icon>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "ShortCheckBox",
  data: () => ({
    checked: false
  }),
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "#000000"
    }
  },
  components: {},
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    toggle() {
      if (this.disabled) {
        return;
      }
      this.checked = !this.checked;
      this.updateValue(this.checked);
      this.$emit('click');
    }
  },
  watch: {
    value: function(newValue) {
      this.checked = newValue;      
    }    
  },
  mounted() {
    this.checked = this.value;
  },
  computed: {}
};
</script>
